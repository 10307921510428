import { Route } from 'next';
import Link from 'next/link';
import styles from './CommonFooter.module.scss';

function CommonFooter({
  siteName,
  copyright,
  showOld = false,
}: {
  siteName: string;
  copyright: string;
  showOld?: boolean;
}) {
  // toはサイト内リンク、hrefは外部リンク
  const menuListLeft = () => {
    const list = [
      { to: '/', text: 'トップページ' },
      { to: '/lotteries?session=true', text: '開催中のくじ一覧' },
      { to: '/lotteries', text: '新着くじ一覧' },
      { to: '/informations', text: 'お知らせ' },
    ];
    if (showOld) {
      list.push({
        to: 'https://raffle-event.jp/account/prize-list',
        text: '旧Raffleで獲得した景品を確認',
      });
    }
    return list;
  };
  const menuListRight = () => {
    return [
      { to: '/auth/signin', text: 'ログイン・新規会員登録' },
      { to: '/faq', text: 'よくある質問' },
      // { to: '/contact', text: 'お問い合わせ' },
      { to: '/terms', text: '利用規約' },
      {
        href: `${process.env.NEXT_PUBLIC_MAIN_URL}/policy`,
        text: 'プライバシーポリシー',
      },
      { to: '/tokusho', text: '特定商取引法に基づく表記' },
      { to: '/shikin', text: '資金決済法に基づく表記' },
    ];
  };
  return (
    <footer className={styles.componentStyle}>
      <div className={styles.container}>
        <div className={styles.footerTitle}>
          <img src='/logo-white.png' alt={siteName} />
        </div>
        <div className={styles.menuListWrapper}>
          <ul className={styles.menuList}>{menuList(menuListLeft())}</ul>
          <ul className={styles.menuList}>{menuList(menuListRight())}</ul>
        </div>
        <p className={styles.copyright}>{copyright}</p>
      </div>
    </footer>
  );
}

function menuList(list: Array<{ href?: string; to?: string; text: string }>) {
  const result: Array<React.ReactNode> = [];
  list.forEach((item, index) => {
    result.push(
      <li key={index}>
        {item.href ? <a href={item.href}>{item.text}</a> : <Link href={item.to as Route}>{item.text}</Link>}
      </li>,
    );
  });

  return result;
}

export default CommonFooter;
